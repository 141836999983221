import { TENANT, MULTITENANT_CONSTANTS } from "../Resources/Multitenant/tenantConstants";
import {
  URL_BASE,
  URL_CAS,
  URL_SERGIO,
  URL_SGCA,
  URL_IDEN,
  URL_ANALYTICS,
  URL_REM,
  URL_SDP,
  URL_BASE_V2,
  URL_ORCH,
  URL_GAME_RANKINGS,
  URL_STATS,
  URL_COMM, URL_SCEEN,
} from "./servicesConstants";
import { USER_ANONYMOUS } from "../../../home/src/Pages/Home/HomeComponentConstants";

// TODO: Pendiente de quitar los copy/paste de los baseUrl una vez nos podamos diferenciar los v2 de los v1
export const getWidgetsServices = (sectionSelected, callback) => {
  fetch(`${URL_BASE}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/screens/${sectionSelected}?fields=*.*`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getBannersWidgetServices = (id, limit, page, sort, callback) => {
  fetch(
    `${URL_BASE}${URL_SGCA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/widgets/${id}/content?limit=${limit}&page=${page}&sort=${sort}&filter%5Bstatus%5D=published`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getMenu = (callback) => {
  const baseUrl = URL_BASE_V2;
  fetch(`${baseUrl}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/interfaz`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getPreview = async (contentId) => {
  const baseUrl = URL_BASE_V2;
  try {
    const response = await fetch(`${baseUrl}${URL_REM}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/preview/${contentId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTenantRules = (callback) => {
  fetch(`${URL_BASE}${URL_CAS}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/rules`)
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getAvailableAvatarsService = (appname, token, callback) => {
  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  fetch(`${URL_BASE}${URL_SERGIO}${appname}/avatars?fields=*.*`, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

// ------------ USER ---------------
export const createUserService = (appname, body, callback = () => {}, createUserErrorCallback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      createUserErrorCallback();
      console.error("Error:", error);
    });
};

export const getUserDataService = (appname, username, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getUserInterestsService = (appname, token, callback) => {
  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  fetch(`${URL_BASE}${URL_SERGIO}${appname}/interests?fields=%2A.%2A`, {
    method: "GET",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateUserInterestsService = (appname, username, body, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/interests/list`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const deleteUserInterestsService = (appname, username, interests, token, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/interests/list?interests=${interests}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getFileService = (appname, fileId, token, callback) => {
  fetch(`${URL_BASE}${URL_REM}${appname}/files/${fileId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateUserDataService = (
  appname,
  username,
  token,
  callback = () => {},
  errorCallback = () => {},
  body,
) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}`, {
    method: "PATCH",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

export const updateUserPasswordService = (
    appname,
    username,
    token,
    callback = () => {},
    errorCallback = () => {},
    body,
) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user-pass/${username}`, {
    method: "PATCH",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        errorCallback(error);
      });
};


export const generateUsernameService = (appname, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/generateusername`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const publishContentService = (payload, token, callback) => {
  fetch(`${URL_BASE}${URL_SGCA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/contents-confirm?payload=${payload}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const headerWithToken = (username, token) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (username !== USER_ANONYMOUS) headers.Authorization = "Bearer " + token;
  return headers;
};

// ------------ ANALYTICS ---------------
export const logUserAnalyticService = (appname, username, action, key, value, token, contentId) => {
  let url = `${URL_BASE}${URL_ANALYTICS}${appname}/user/${username}?action=${action}&key=${key}&value=${value}`;

  if (contentId) {
    url += `&contentId=${contentId}`;
  }

  fetch(url, {
    method: "GET",
    headers: headerWithToken(),
  }).catch((error) => {
    console.error("Error", error);
  });
};

// ------------ PRODUCTS ---------------
export const getProductCheckoutService = (id, appname, username, locale, token, body, callback) => {
  fetch(`${URL_BASE}${URL_SDP}${appname}/checkout/${username}/${id}?locale=${locale}`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    });
};

export const getProductsService = (appname, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_SDP}${appname}/products`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getProductsDashboardService = (appname, username, locale, token, body, callback) => {
  fetch(`${URL_BASE}${URL_SDP}${appname}/portal/${username}/?locale=${locale}`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    });
};

// ------------ CATALOGUE ---------------
// Petición necesaria para obtener el id a utilizar en el servicio de obtención de la data de los catálogos y de los children.
// Se utiliza el id del primer elemento de la lista
export const getRootNodes = (appname, callback) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/nodes-root`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// Este servicio solo se llama en caso de que el campo has_children del nodo que tengamos selccionado por defecto venga a true
export const getChildrenNodesService = (appname, id, callback) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/nodes-children/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// Hay que añadir los parámetro de filtrado filters?.forEach {endpoint += "&filter${it.key}=${it.value}"}
export const getCatalogueDataService = (appname, id, callback, limit, sort, page, callbackError) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/nodes-contents/${id}?limit=${limit}&page=${page}&sort=${sort}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => {
      callbackError(err);
      console.error(err);
    });
};

// ------------ COMMENTS ---------------
export const addCommentsService = (appname, body, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/comments`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const updateCommentService = (appname, id, body, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/comments/${id}`, {
    method: "PATCH",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getCommentsService = (appname, id, limit, page, sort, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/comments/content/${id}?limit=${limit}&page=${page}&sort=${sort}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// ------------ RATINGS ---------------
export const getRatingsService = (appname, callback) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/ratings`, { method: "GET", headers: { "Content-Type": "application/json" } })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const addRatingService = (appname, body, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_SGCA}${appname}/rate`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// ------------ RANKING ---------------
export const getRankingService = (appname, username, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_ORCH}${appname}/consumer-stats/${username}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getBoardRankingsService = (appname, id, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_GAME_RANKINGS}${appname}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getRewardsService = (appname, username, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_ORCH}${appname}/${URL_STATS}/${username}`, {
    method: "GET",
    headers: { ...(token && { Authorization: "Bearer " + token, "Content-Type": "application/json" }) },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// ------------ FAVORITES ---------------
export const getUserFavorites = (appname, username, token, callback) => {
  fetch(`${URL_BASE_V2}${URL_IDEN}users/${appname}/user/${username}/favorites?core=false`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const addFavoriteService = (appname, username, id, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/favorites?contentid=${id}`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const deleteFavoriteService = (appname, username, id, token, callback = () => {}) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user/${username}/favorites?contentid=${id}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// ----------- SEARCH -------------------
export const getSearchContentsService = (sort, page, limit, searchText, callback) => {
  fetch(
    `${URL_BASE}${URL_SGCA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/search?sort=${sort}&page=${page}&limit=${limit}&searchin=title,tags&filter[status]=published&filter[tag][contains]=${searchText}&filter[title][contains]=${searchText}`,
    { method: "GET", headers: { "Content-Type": "application/json" } },
  )
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// ------------ SCREENS / WIDGETS ---------------
export const getMainScreenService = (appname, token = undefined, callback, errorCallback, username) => {
  const screenUrl = !!username ? `/${URL_SCEEN}?username=${username}` : `/${URL_SCEEN}`;

  fetch(`${URL_BASE_V2}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}${screenUrl}`, {
    method: "GET",
    headers: { ...(token && username && { Authorization: "Bearer " + token, "Content-Type": "application/json" }) },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      errorCallback(error);
    });
};

export const getScreenService = (id, appname, token = undefined, callback, errorCallback, username) => {
  const screenUrl = !!username ? `/${URL_SCEEN}/${id}?username=${username}` : `/${URL_SCEEN}/${id}`;
  fetch(`${URL_BASE_V2}${URL_SERGIO}${MULTITENANT_CONSTANTS[TENANT].tenantServer}${screenUrl}`, {
    method: "GET",
    headers: { ...(token && { Authorization: "Bearer " + token, "Content-Type": "application/json" }) },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      errorCallback(error);
    });
};

/*export const getAppLinks = (callback) => {
  const apiKey = MULTITENANT_CONSTANTS[TENANT]?.firebaseApiKey?.[ENVIRONMENT];

  if (!apiKey) return;

  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;

  const body = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://ohjazz.page.link",
      link: "https://watch.ohjazz.tv",
      androidInfo: {
        androidPackageName: "es.cinfo.andromeda",
      },
      iosInfo: {
        iosBundleId: "com.ohjazztv.ohjazz",
        iosIpadBundleId: "com.ohjazztv.ohjazz",
        iosAppStoreId: "1618718051",
      },
    },
  };*/

  /*fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};*/

// ------------ CONTENTS ----------------
export const getContentsService = (appname, id, token, payload, callback) => {
  let url = `${URL_BASE}${URL_SGCA}${appname}/contents/${id}`;
  if (!!payload) {
    url += `?payload=${payload}`;
  }
  fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getAnonContentsService = (appname, id, payload, callback) => {
  let url = `${URL_BASE}${URL_SGCA}${appname}/anon-contents/${id}`;
  if (!!payload) {
    url += `?payload=${payload}`;
  }
  fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getRelatedContentsService = (appname, id, language, callback) => {
  fetch(`${URL_BASE_V2}${URL_SGCA}${appname}/contents-related/${id}/${language}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const getSerialContentsService = (appname, id, allowed = undefined, callback) => {
  fetch(
    `${URL_BASE}${URL_SGCA}${appname}/contents-items/${id}?limit=25&page=1&sort=episode&filter%5Bstatus%5D=published`,
    { method: "GET", headers: { "Content-Type": "application/json" } },
  )
    .then((response) => response.json())
    .then((data) => {
      if (allowed) {
        callback(data, allowed);
      } else {
        callback(data);
      }
    })
    .catch((err) => console.error(err));
};

export const getContentPermissions = (appname, id, token, contentType, callback) => {
  fetch(`${URL_BASE}${URL_CAS}${appname}/content/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
  })
    .then((response) => response.json())
    .then((data) => callback(data, contentType))
    .catch((err) => console.error(err));
};

export const getVodService = (appname, smil, callback) => {
  fetch(`${URL_BASE}s2/${appname}/vod?smil=${smil}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// -------------- REPORTS ---------------
export const getReportTypesService = async (appname, callback) => {
  return fetch(`${URL_BASE}${URL_SGCA}${MULTITENANT_CONSTANTS[TENANT].tenantServer}/report-types`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

export const reportContentService = (appname, id, reason, token, callback) => {
const payload = {reason: 11, message: reason};
  fetch(`${URL_BASE}${URL_SGCA}${appname}/contents-report/${id}`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((err) => console.error(err));
};

// -------------- LOGIN --------------
export const loginService = (appname, body, callback, errorCallback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback({ data }))
    .catch((err) => errorCallback(err));
};

// -------------- REGISTER ---------------
export const registerService = (appname, body, callback, errorCallback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/user`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback({ data }))
    .catch((err) => errorCallback(err));
};

export const validateUsernameService = (appname, username, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/checkbyusername?value=${username}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback({ data, username }))
    .catch((err) => console.error(err));
};

export const validateEmailService = (appname, email, callback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/checkbyemail?value=${email}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => callback({ data, email }))
    .catch((err) => console.error(err));
};

// --------------- RECOVER --------------
export const recoverUserService = (appname, username, body = {}, callback = () => {}, errorCallback) => {
  fetch(`${URL_BASE}${URL_IDEN}users/${appname}/reset-pass/${username}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      errorCallback(error)
      console.error("Error:", error);
    });
};

// --------------- MUI ----------------
export const checkMuiIconService = (icon, errorCallback) => {
  const url = "https://unpkg.com/ionicons@5.2.3/dist/ionicons/svg/" + icon + ".svg";
  return fetch(url, {
    method: "GET",
  }).then((response) => {
    if (!response.ok) {
      errorCallback();
    }
  });
};

// ------------ NOTIFICATIONS ---------------
export const getNotificationsService = (appname, username, token, callback, errorCallback) => {
  fetch(`${URL_BASE}${URL_COMM}notifications/${appname}/list/${username}?limit=100&page=1&sort=created_on`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      if (!!errorCallback) {
        errorCallback(error);
      }
    });
};

export const deleteNotificationService = (appname, id, token, callback, errorCallback = null) => {
  fetch(`${URL_BASE}${URL_COMM}notifications/${appname}/delete/${id}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      if (!!errorCallback) {
        errorCallback(error);
      }
    });
};

export const readNotificationService = (appname, id, token, callback, errorCallback = null) => {
  fetch(`${URL_BASE}${URL_COMM}notifications/${appname}/read/${id}`, {
    method: "PATCH",
    headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      if (!!errorCallback) {
        errorCallback(error);
      }
    });
};
export const deleteUserService = (appname, username, token, callback, errorCallback = () => {}) => {
  fetch(`${URL_BASE_V2}${URL_IDEN}users/${appname}/baja/${username}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      let responseData = null;
      try {
        responseData = await response.json();
      } catch (error) {
        console.warn("La respuesta no tiene un cuerpo válido:", error);
      }

      if (!response.ok) {
        throw new Error(`Error al dar de baja el usuario: ${response.status}`);
      }
      callback(responseData || { message: "Operación exitosa" });
    })
    .catch((error) => {
      console.error("Error al dar de baja el usuario:", error.message);
      errorCallback(error);
    });
};
