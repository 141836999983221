import React from "react";
import { useTranslation } from "react-i18next";
import "./ErrorMessageModalComponent.css";
import { useHistory } from "react-router-dom";
import {URL_LOGIN} from "../Routes/Location";
import {MULTITENANT_CONSTANTS, TENANT} from "../../Resources/Multitenant/tenantConstants";
import {useKeycloak} from "@react-keycloak/web";

const ErrorMessageModalComponent = ({
  textTitle = "error.modal.common.title",
  textBody = "error.modal.rating.message",
    textButton = "global.button.go.back",
  url = null,
  onClose = null,
    action = null
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { keycloak} = useKeycloak();

  const handleActionModal = () => {
    url && url !== URL_LOGIN ? history.push(url) : url === URL_LOGIN ? keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale }) : action ? action() :  onClose && onClose();
  };

  return (
    <div className="ModalBackground">
      <div className="ErrorMessageModal">
        <strong className={"ErrorModalTitle"}>{t(textTitle)}</strong>
        <div className={"ModalContent"}>
          <p>{t(textBody)}</p>
        </div>
        <div className="ButtonContainer">

          <button onClick={handleActionModal} className="BotonVer BotonVerV2">
            {t(textButton)}
          </button>

          {!!onClose &&
          <button onClick={onClose} className="BotonVer BotonVerV2">
                {t("global.button.close")}
          </button>
          }
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageModalComponent;
